<template>
  <header class="bg-point" :class="{ toggle: layout.toggle }">
    <div class="top">
      <div class="toggle" @click="toggle()">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <router-link :to="`/manage/${$route.params.projectType}/${$route.params.projectSeq}`" class="title">진행자 페이지</router-link>
      <span class="badge color-white border-white border ml-2">{{ projectType === "invest" ? "증권형" : "후원형" }}</span>
      <div class="media align-items-center">
        <div class="guide">
          <i class="ni ni-single-copy-04 text-white font-lg"></i>
          <span class="mb-0 text-sm">이용가이드</span>
        </div>
        <a href="/mypage" target="_blank" rel="noopener noreferrer" class="profile" title="마이페이지로 이동" v-if="$store.state.account.loggedIn">
          <MemberPicture :memberSeq="$store.state.account.memberSeq"/>
          <span class="media-body ml-2 d-none d-lg-inline-block">
            <span class="mb-0 text-sm">{{ $store.state.account.memberName }}</span>
          </span>
        </a>
      </div>
    </div>
    <div class="project" :class="{skeleton: !project.loaded}">
      <div class="row align-items-center pt-3 pb-4">
        <div class="info col-lg-6">
          <a :href="`/${$route.params.projectType === 'invest' ? 'invest' : 'reward'}/${$route.params.projectSeq}`" target="_blank" rel="noopener noreferrer" class="title">{{ project.projectName }}</a>
          <div class="desc pt-1 font-sm">
            <span>{{ project.simpleText }}</span>
          </div>
        </div>
        <div class="link col-lg-6">
          <a :href="`/${$route.params.projectType === 'invest' ? 'invest' : 'reward'}/${$route.params.projectSeq}`" target="_blank" rel="noopener noreferrer" class="pop btn font-sm color-white">
            <span>프로젝트 바로가기</span>
            <i class="fa fa-external-link" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";

function Component(initialize) {
  this.name = "componentHeader";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture},
  mixins: [mixin],
  props: {
    layout: Object,
    toggle: Function,
    project: Object,
    projectType: String,
  },
  setup() {
    const component = new Component(() => {
    });

    const state = reactive({});

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
header {
  color: #fff;
  //background: $colorPoint;
  padding: 0 $px40 $px70 $px40;

  > .top {
    border-bottom: $px1 solid rgba(255, 255, 255, .08);
    font-weight: normal;
    padding: $px25 0;

    .toggle {
      display: inline-block;
      height: $px20;
      width: $px25;
      padding: 0;
      cursor: pointer;
      position: absolute;
      top: $px31;
      left: $px25;

      > div {
        height: $px2;
        width: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.25s;

        &:nth-child(2) {
          top: $px8;
        }

        &:nth-child(3) {
          top: $px16;
        }
      }
    }

    > .title {
      font-size: $px18;
      font-weight: 600;
      vertical-align: middle;
    }

    > .media {
      position: absolute;
      top: 0;
      right: 0;
      padding: $px20 $px40;

      > .guide {
        > i {
          margin-right: $px6;
          vertical-align: middle;
        }

        > span {
          font-weight: normal;
          vertical-align: middle;
        }
      }

      > .profile {
        margin-left: $px25;

        > span {
          vertical-align: middle;
        }
      }
    }
  }

  > .project {
    > .row > div {
      &.info {
        .title {
          font-weight: 500;
        }

        .desc {
          color: $colorBackground;
          font-weight: normal;
        }
      }

      &.link {
        text-align: right;

        .btn {
          border: $px1 solid #fff;
          font-weight: normal;
          padding: $px15;

          i {
            margin-left: $px7;
          }

          &:hover {
            background: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }

    &.skeleton {
      > .row > div {
        &.info {
          .title, .desc > span {
            @include skeleton;
          }
        }

        &.link .btn {
          @include skeleton;
        }
      }
    }
  }

  &.toggle {
    > .top .toggle {
      > div:nth-child(1) {
        top: $px8;
        transform: rotate(45deg);
      }

      > div:nth-child(2) {
        opacity: 0;
      }

      > div:nth-child(3) {
        top: $px8;
        transform: rotate(-45deg);
      }
    }
  }

  @media(max-width: 991px) {
    padding-left: $px25;
    padding-right: $px25;

    > .top {
      padding-left: $px40;

      > .media {
        padding-right: $px20;

        > .guide {
          display: none;
        }
      }
    }

    > .project > .row > div {
      &.link {
        display: none;
      }
    }
  }
}
</style>